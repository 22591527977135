import { useEffect, useState } from "react"
import { axiosInstance } from "../../axios-instance";
import "./AddUser.css"
import { useNavigate } from "react-router-dom";

const AddUser = () => {
    const [sectionId, setSectionId] = useState()
    const [hood, setHood] = useState()
    const [hoods, setHoods] = useState()
    const [selectValue, setSelectValue] = useState()
    const [fullName, setFullName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [telephone, setTelephone] = useState()
    const [idNumber, setIdNumber] = useState()
    const [address, setAddress] = useState()
    const [password, setPassword] = useState()
    const [salary, setSalary] = useState()
    const [status, setStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [sections, setSections] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        axiosInstance().get(`api/get-locations`)
            .then(res => {
                setSections(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    useEffect(() => {
        axiosInstance().get(`api/get-sub-locations/${sectionId}`)
            .then(res => {
                setHoods(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [sectionId])
    const handleSubmit = (e) => {
        e.preventDefault()
        axiosInstance().post(`api/add-user`, { full_name: fullName, phone_number: phoneNumber, telephone: telephone, ID_number: idNumber, address, sub_location_id: hood, password, salary, role: selectValue && selectValue != "" && selectValue != "نوع الحساب" ? selectValue : "عميل" })

            .then(res => {
                navigate("/Dashboard")
                setStatus("true")
            })
            .catch(err => {
                console.log(err.response.data.message)
                setStatus("false")
                setErrorMessage(err.response.data.message)
            })
    }

    return (
        <>
            <h4>إضافة حساب</h4>
            <form className="row g-3 form-add-user" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="firstName" className="form-label">الاسم الكامل :</label>
                        <input type="text" className="form-control" id="firstName" onChange={(e) => setFullName(e.target.value)} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="phoneNumber" className="form-label">رقم الهاتف :</label>
                        <input type="text" minLength="0" max="10" className="form-control" id="phoneNumber" onChange={(e) => setPhoneNumber(e.target.value)} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input select-row">
                        <select id="inputState" className="form-select" required defaultValue="القطاع" onChange={(e) => setSectionId(e.target.value)}>
                            <option  >القطاع</option>
                            {sections && sections.map(item => (<>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            </>))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input select-row">
                        <select id="inputState" className="form-select" required defaultValue="الحي" onChange={(e) => setHood(e.target.value)}>
                            <option  >الحي</option>
                            {hoods && hoods.map(item => (
                                <>
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                </>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="number" className="form-label">العنوان</label>
                        <input type="text" className="form-control" id="number" onChange={(e) => setAddress(e.target.value)} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="number" className="form-label"> الرقم الارضي :</label>
                        <input type="text" minLength="0" maxLength="7" className="form-control" id="number" onChange={(e) => setTelephone(e.target.value)} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="nationalId" className="form-label">الرقم الوطني :</label>
                        <input type="text" className="form-control" id="nationalId" onChange={(e) => setIdNumber(e.target.value)} required />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label htmlFor="password" className="form-label"> كلمة المرور :</label>
                        <input type="password" autoComplete="new-password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                </div>
                <div className={selectValue == null ? "select-con" : "select-not-null row"}>
                    <div className={selectValue != "نوع الحساب" && selectValue != null ? "form-input col-md-6" : "form-input"}>
                        <select id="inputState" className="form-select" onChange={(e) => setSelectValue(e.target.value)} required defaultValue="نوع الحساب">
                            <option  >نوع الحساب</option>
                            <option >عميل</option>
                            <option>موظف</option>
                        </select>
                    </div>
                    {selectValue == "موظف" && <>
                        <div className="form-input col-md-6">
                            <label htmlFor="specificLocation" className="form-label"> الراتب:</label>
                            <input type="text" className="form-control" id="specificLocation" onChange={(e) => setSalary(e.target.value)} required />
                        </div>
                    </>}
                </div>
                <div className="col-md-12 add-con">
                    {status == "true" && <div className="alert alert-success" role="alert">
                        تم اضافة المستخدم بنجاح
                    </div>}
                    {status == "false" && <div className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>}
                </div>
                <div className="col-md-12 add-con">
                    <button type="submit" className="add-button">إضافة</button>
                </div>
            </form>
        </>
    )
}

export default AddUser