import "./Home.css"
import { axiosInstance } from "../../axios-instance";
import Cookies from "js-cookies"
import { Fragment, useEffect, useState } from "react";

const Home = () => {
    const [role, setRole] = useState()
    const [data, setData] = useState()
    useEffect(() => {
        axiosInstance().get(`api/home`, { token: Cookies.getItem("token") })
            .then(res => {
                setRole(res.data.data.role)
                setData(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <Fragment className="home-fragment">

            <h4>الرئيسية</h4>
            <div className="widgets-con home-widget-con">
                {data && role == "admin" &&
                    <>
                        <div className="widget-1 home-widget-1">
                            <p> عدد العدادات</p>
                            <p>{data.ampere_meters}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p>عدد المستخدمين</p>
                            <p>{data.clients}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p>عدد الفواتير الغير مدفوعة</p>
                            <p>{data.unPayed_bills}</p>
                        </div>

                        <div className="widget-1 home-widget-1">
                            <p>عدد الفواتير المدفوعة</p>
                            <p>{data.payed_bills}</p>
                        </div>

                        <div className="widget-1 home-widget-1">
                            <p>عدد الموظفين</p>
                            <p>{data.employees}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p >الدورة الحالية</p>
                            <p>{data.current_cycle}</p>
                        </div>
                    </>}
                {data && role == "client" &&
                    <>
                        <div className="widget-1 home-widget-1">
                            <p>تاريخ التسجيل</p>
                            <p>{data.joined_at}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p> مجموع المدفوعات</p>
                            <p>{data.total_payments}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p> عدد العدادات</p>
                            <p>{data.ampere_meters}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p >الدورة الحالية</p>
                            <p>{data.current_cycle}</p>
                        </div>
                    </>}
                {data && role == "employee" &&
                    <>
                        <div className="widget-1 home-widget-1">
                            <p>تاريخ التسجيل</p>
                            <p>{data.joined_at}</p>
                        </div>
                        <div className="widget-1 home-widget-1">
                            <p >الدورة الحالية</p>
                            <p>{data.current_cycle}</p>
                        </div>

                    </>}


            </div>

        </Fragment>
    )
}

export default Home