import axios from "axios";
import { BaseUrl } from "./assets/Data"
import Cookies from "js-cookies"

export const axiosInstance = () => {
    const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${Cookies.getItem("token")}`,

    };
    const instance = axios.create({
        baseURL: `${BaseUrl}`,
        headers: headers
    })

    return instance
}