import { useEffect, useState } from "react"
import { axiosInstance } from "../../axios-instance";
import "./Settings.css"
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const [amperePrice, setAmperePrice] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [status, setStatus] = useState()
    const [paymentStatus, setPaymentStatus] = useState()
    const [paymentType, setPaymentType] = useState()
    const [types, setTypes] = useState()
    const [ampereCurrentPrice, setAmpereCurrentPrice] = useState()
    const [meterCurrentPrice, setMeterCurrentPrice] = useState()
    const [meterPrice, setMeterPrice] = useState()
    const [meterStatus, setMeterStatus] = useState()
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        axiosInstance().post(`api/change-ampere-price`, { ampere_price: amperePrice })
            .then(res => {
                setStatus(true)
                console.log(res)

            })
            .catch(err => {
                console.log(err)
                setStatus(false)
                setErrorMessage(err.response.data.message)
            })
    }
    const handleTypeChange = (e) => {
        e.preventDefault()
        axiosInstance().post(`api/add-payment-type`, { payment_type: paymentType })
            .then(res => {
                setPaymentStatus(true)
                console.log(res)

            })
            .catch(err => {
                console.log(err)
                setPaymentStatus(false)
                setErrorMessage(err.response.data.message)
            })
    }
    useEffect(() => {
        axiosInstance().get(`api/get-payment-types`)
            .then(res => {
                console.log(res)
                setTypes(res.data.data)

            })
            .catch(err => {
                console.log(err)

            })

    }, [])
    useEffect(() => {
        axiosInstance().get(`api/get-settings`)
            .then(res => {
                setAmpereCurrentPrice(res.data.data.ampere_price)
                setMeterCurrentPrice(res.data.data.ampere_meter_price)

            })
            .catch(err => {
                console.log(err)

            })
    }, [])
    const handleDelete = (type) => {
        axiosInstance().post(`api/delete-payment-type`, { payment_type: type })
            .then(res => {
                console.log(res)
                navigate(0)


            })
            .catch(err => {
                console.log(err)

            })
    }
    const handleMeterSubmit = (e) => {
        e.preventDefault()
        axiosInstance().post(`api/change-ampere-meter-price`, { ampere_meter_price: meterPrice })
            .then(res => {
                setMeterStatus(true)
                console.log(res)

            })
            .catch(err => {
                console.log(err)
                setMeterStatus(false)
                setErrorMessage(err.response.data.message)
            })
    }
    return (
        <>
            <h4>الإعدادات</h4>
            <div className="container settings-con">
                <div className="row">
                    <div className="col-md-6 mb-5">
                        <div className="settings-employee-salary">
                            <h6>أنواع المصاريف :</h6>

                            {types && types.map(item => (
                                <>
                                    <div className="meter-price-text" key={Math.random()}>
                                        <p className="m-0 p-0">  {item} </p>
                                        <svg onClick={() => handleDelete(item)} className="settings-delete" xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="none">
                                            <path d="M8 0C7.47656 0 6.94141 0.191244 6.5625 0.585938C6.18359 0.980631 6 1.53809 6 2.08333V3.125H0V5.20833H1V21.875C1 23.5881 2.35547 25 4 25H16C17.6445 25 19 23.5881 19 21.875V5.20833H20V3.125H14V2.08333C14 1.53809 13.8164 0.980631 13.4375 0.585938C13.0586 0.191244 12.5234 0 12 0H8ZM8 2.08333H12V3.125H8V2.08333ZM3 5.20833H17V21.875C17 22.4528 16.5547 22.9167 16 22.9167H4C3.44531 22.9167 3 22.4528 3 21.875V5.20833ZM5 8.33333V19.7917H7V8.33333H5ZM9 8.33333V19.7917H11V8.33333H9ZM13 8.33333V19.7917H15V8.33333H13Z" fill="#E28104" />
                                        </svg>
                                    </div>
                                </>
                            ))}


                            <div className="settings-button-employee">
                                <a data-bs-toggle="modal" data-bs-target="#employeeSalary">إضافة</a>
                                <div className="modal fade" id="employeeSalary" tabIndex="-1" aria-labelledby="employeeSalaryLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <h5>إضافة نوع</h5>
                                                <form className="settings-form" onSubmit={handleTypeChange}>
                                                    <div className="row g-3 mb-3">
                                                        <div className="col">
                                                            <input type="text" className="form-control" onChange={(e) => setPaymentType(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    {paymentStatus == true && <div className="alert alert-success sign-in-danger" role="alert">
                                                        اضافة النوع تم بنجاح
                                                    </div>}
                                                    {paymentStatus == false && <div className="alert alert-danger sign-in-danger" role="alert">
                                                        {errorMessage}
                                                    </div>}
                                                    <button className="add-meter-button-users" >إضافة</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="settings-meter-price">
                            <div className="meter-price-text margin-meter-price">
                                <p className="m-0 p-0">  سعر الأمبير الحالي: </p>
                                <p className="m-0 p-0">{ampereCurrentPrice}   ل . س</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-input">
                                    <label htmlFor="firstName" className="form-label">تعديل سعر الأمبير  : </label>
                                    <input type="text" className="form-control" id="firstName" required onChange={(e) => setAmperePrice(e.target.value)} />
                                </div>
                                <div className="settings-button-employee">
                                    {status == true && <div className="alert alert-success sign-in-danger" role="alert">
                                        اضافة السعر الجديد تم بنجاح
                                    </div>}
                                    {status == false && <div className="alert alert-danger sign-in-danger" role="alert">
                                        {errorMessage}
                                    </div>}
                                    <button className="settings-save">حفظ</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="settings-meter-price">
                            <div className="meter-price-text margin-meter-price">
                                <p className="m-0 p-0">  سعر العداد الحالي: </p>
                                <p className="m-0 p-0">{meterCurrentPrice}   ل . س</p>
                            </div>
                            <form onSubmit={handleMeterSubmit}>
                                <div className="form-input">
                                    <label htmlFor="firstName" className="form-label"> تعديل سعر العداد  : </label>
                                    <input type="text" className="form-control" id="firstName" required onChange={(e) => setMeterPrice(e.target.value)} />
                                </div>
                                <div className="settings-button-employee">
                                    {meterStatus == true && <div className="alert alert-success sign-in-danger" role="alert">
                                        اضافة السعر الجديد تم بنجاح
                                    </div>}
                                    {meterStatus == false && <div className="alert alert-danger sign-in-danger" role="alert">
                                        {errorMessage}
                                    </div>}
                                    <button className="settings-save">حفظ</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings