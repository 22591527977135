import { Table } from "antd"
import "./Users.css"
import { axiosInstance } from "../../axios-instance";
import { useEffect, useState } from "react";
import { GrFormAdd } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookies"



const Users = () => {
    const [filter, setFilter] = useState()
    const [dataSource, setDataSource] = useState()
    const [meterNumber, setMeterNumber] = useState()
    const [isLimited, setIsLimited] = useState()
    const [consumption, setConsumption] = useState("0")
    const [search, setSearch] = useState()
    const [status, setStatus] = useState()
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState()
    const [totalPages, setTotalPages] = useState()
    const [isSearch, setIsSearch] = useState(false)
    const [abilities, setAbilities] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        fetchData(1)
    }, [filter])
    const handleAddMeter = (e, ID) => {
        e.preventDefault()
        axiosInstance().post(`api/add-meter`, { user_id: ID, meter_number: meterNumber, is_limited: isLimited == "محدود" ? 1 : isLimited == "غير محدود" && 2, consumption })
            .then(res => {
                window.location.assign("/Dashboard/Users")
                setStatus(true)
            })
            .catch(err => {
                console.log(err)
                setStatus(false)
                setErrorMessage(err.response.data.message)
            })
    }
    const handleClick = (ID) => {
        navigate("/UsersDetails", { state: { id: ID } })
    }
    const handleSearch = (page) => {
        setLoading(true)
        axiosInstance().get(`api/search-user${search == null || search == "" ? `/${null}` : `/${search}`}${search != "" ? `?page=${page}` : ""}`)
            .then(res => {
                setTotalPages(res.data.data.total)
                setLoading(false)
                setIsSearch(true)
                setDataSource(res.data.data.data)
            })
            .catch(err => {
                setLoading(true)
                setIsSearch(false)
                console.log(err)
            })
    }
    const fetchData = (page) => {
        setLoading(true)
        axiosInstance().get(`api/get-users/${filter}?page=${page}`)
            .then(res => {

                setDataSource(res.data.data.data)
                setTotalPages(res.data.data.total)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(true)
            })
    }
    useEffect(() => {
        axiosInstance().post(`api/check-accessibility`, { token: Cookies.getItem("token") })
            .then(res => {
                setAbilities(res.data.data.abilities)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const columns = [

        {
            title: 'اسم المستخدم',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: ' تاريخ انشاء الحساب',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'نوع الحساب',
            dataIndex: 'role_id',
            key: 'role_id',
            render: (text, record) => (
                <p>{record.role_id == 3 ? "عميل" : record.role_id == 2 && "موظف"}</p>

            )
        },
        {
            dataIndex: 'profile',
            key: 'profile',
            render: (text, record) => (
                <div className="profile-con-table">
                    <svg className="profile-svg" onClick={() => handleClick(record.id)} xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M8.29251 0C5.09536 0 2.48775 2.71571 2.48775 6.04541C2.48775 8.1269 3.50812 9.97561 5.05325 11.0653C2.0958 12.3877 0 15.4441 0 18.9999H1.6585C1.6585 15.1743 4.61919 12.0908 8.29251 12.0908C11.9658 12.0908 14.9265 15.1743 14.9265 18.9999H16.585C16.585 15.4441 14.4892 12.3877 11.5318 11.0653C13.0769 9.97561 14.0973 8.1269 14.0973 6.04541C14.0973 2.71571 11.4897 0 8.29251 0ZM8.29251 1.72726C10.5924 1.72726 12.4388 3.65019 12.4388 6.04541C12.4388 8.44064 10.5924 10.3636 8.29251 10.3636C5.99264 10.3636 4.14626 8.44064 4.14626 6.04541C4.14626 3.65019 5.99264 1.72726 8.29251 1.72726Z" fill="#0465E2" />
                    </svg>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
                        <path d="M6.05714 0C5.66082 0 5.25563 0.145344 4.96875 0.44531C4.68186 0.745275 4.54285 1.16894 4.54285 1.58332V2.37498H0V3.95831H0.757142V16.6249C0.757142 17.9268 1.78343 18.9999 3.02857 18.9999H12.1143C13.3594 18.9999 14.3857 17.9268 14.3857 16.6249V3.95831H15.1428V2.37498H10.6V1.58332C10.6 1.16894 10.461 0.745275 10.1741 0.44531C9.88722 0.145344 9.48203 0 9.08571 0H6.05714ZM6.05714 1.58332H9.08571V2.37498H6.05714V1.58332ZM2.27143 3.95831H12.8714V16.6249C12.8714 17.064 12.5343 17.4166 12.1143 17.4166H3.02857C2.60859 17.4166 2.27143 17.064 2.27143 16.6249V3.95831ZM3.78571 6.33329V15.0416H5.3V6.33329H3.78571ZM6.81428 6.33329V15.0416H8.32857V6.33329H6.81428ZM9.84285 6.33329V15.0416H11.3571V6.33329H9.84285Z" fill="#E28104" />
                    </svg> */}

                    <a className="add-meter-button" data-bs-toggle="modal" data-bs-target="#exampleModal" >
                        <GrFormAdd size={20} />
                    </a>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h5>اضافة عداد</h5>
                                    <form onSubmit={(e) => handleAddMeter(e, record.id)}>
                                        <div className="row g-3 mb-3">
                                            <div className="col">
                                                <input type="text" className="form-control" placeholder="رقم العداد" aria-label="meter-number" onChange={(e) => setMeterNumber(e.target.value)} />
                                            </div>
                                            <div className="col">
                                                <input type="text" className="form-control" placeholder="نوع الاشتراك" aria-label="is-limited" onChange={(e) => setIsLimited(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="row g-3 mb-4">
                                            <div className="col">
                                                <input type="text" className="form-control" placeholder="الاستهلاك" defaultValue={consumption} aria-label="consumption" onChange={(e) => setConsumption(e.target.value)} />
                                            </div>
                                        </div>
                                        {status == true && <div className="alert alert-success sign-in-danger" role="alert">
                                            اضافة العداد تم بنجاح
                                        </div>}
                                        {status == false && <div className="alert alert-danger sign-in-danger" role="alert">
                                            {errorMessage}
                                        </div>}
                                        <button className="add-meter-button-users" >إضافة</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },

    ];
    return (
        <div className="users">
            <h4>الحسابات</h4>
            <div className="input-group  search-group">
                <a className="search-icon" onClick={() => handleSearch(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M15.0956 0.543457C9.97087 0.543457 5.79771 4.71662 5.79771 9.84136C5.79771 12.0678 6.57859 14.109 7.88974 15.7107L0.480469 23.1199L1.81704 24.4565L9.22631 17.0472C10.828 18.3584 12.8692 19.1393 15.0956 19.1393C20.2204 19.1393 24.3935 14.9661 24.3935 9.84136C24.3935 4.71662 20.2204 0.543457 15.0956 0.543457ZM15.0956 2.40304C19.2143 2.40304 22.5339 5.72268 22.5339 9.84136C22.5339 13.96 19.2143 17.2797 15.0956 17.2797C10.9769 17.2797 7.65729 13.96 7.65729 9.84136C7.65729 5.72268 10.9769 2.40304 15.0956 2.40304Z" fill="#FAFCFF" />
                    </svg>
                </a>
                <input className="search-con" placeholder="بحث ..." onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="widgets-con">
                {abilities && abilities.includes("view-employees") ?
                    <>
                        <div className="widget-1" onClick={() => setFilter("")}>
                            <p>الكل</p>
                        </div>
                        <div className="widget-1" onClick={() => setFilter("employee")}>
                            <p>موظف</p>
                        </div>
                        <div className="widget-1" onClick={() => setFilter("client")}>
                            <p>عميل</p>
                        </div>
                    </> : <></>
                }

            </div>
            <div className="table-con">
                <Table rowKey={record => record.id} dataSource={dataSource} columns={columns} pagination={{
                    pageSize: 20,
                    total: totalPages,
                    onChange: (page) => {
                        { isSearch == true ? handleSearch(page) : fetchData(page) }
                    }
                }} loading={loading} />
            </div>
        </div>
    )
}

export default Users