import { Table } from "antd";
import "./AllReports.css"
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../axios-instance";
import { useLocation, useNavigate } from "react-router-dom";


const AllReports = () => {
    const [filter, setFilter] = useState()
    const [dataSource, setDataSource] = useState()
    const [loading, setLoading] = useState(true)
    const [totalPages, setTotalPages] = useState()
    const [types, setTypes] = useState()
    const [incomeType, setIncomeType] = useState()
    const [paymentType, setPaymentType] = useState()
    const [amount, setAmount] = useState()
    const [total, setTotal] = useState()
    const [firstDate, setFirstDate] = useState()
    const [secondDate, setSecondDate] = useState()
    const [dateData, setDateData] = useState()
    const [data, setData] = useState()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        fetchData(1)
    }, [filter, location])
    const fetchData = (page) => {
        setLoading(true)
        axiosInstance().get(`api/get-reports/${filter}?page=${page}`)
            .then(res => {
                console.log(res)
                setLoading(false)
                setTotal(res.data.data.total)
                setData(res.data.data.empsTotals)
                setDataSource(res.data.data)

            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }
    const handleApprove = (ID) => {
        axiosInstance().get(`api/approve-report/${ID}`)
            .then(res => {
                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleDisApprove = (ID) => {
        axiosInstance().get(`api/disapprove-report/${ID}`)
            .then(res => {
                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleDelete = (ID) => {
        axiosInstance().get(`api/delete-report/${ID}`)
            .then(res => {
                navigate(0)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const columns = [

        {
            title: 'اسم الموظف',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        // {
        //     title: 'الحالة',
        //     dataIndex: 'is_approved',
        //     key: 'is_approved',
        //     render: (text, record) => (
        //         <p className="m-0 p-0">{record.is_approved == 0 ? "غير واصل" : record.is_approved == 1 && "واصل"}</p>
        //     )
        // },
        {
            title: 'اجمالي الدخل',
            dataIndex: 'sum_income',
            key: 'sum_income',
        },

        {
            title: 'اجمالي الصرف',
            dataIndex: 'sum_outcome',
            key: 'sum_outcome'
        },
        {
            title: 'المجموع',
            dataIndex: 'total',
            key: 'total'
        },

        {
            dataIndex: 'profile',
            key: 'profile',
            render: (text, record) => (
                <div className="profile-con-table" key={record.id}>
                    <svg onClick={() => handleClick(record.user_id)} xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M8.29251 0C5.09536 0 2.48775 2.71571 2.48775 6.04541C2.48775 8.1269 3.50812 9.97561 5.05325 11.0653C2.0958 12.3877 0 15.4441 0 18.9999H1.6585C1.6585 15.1743 4.61919 12.0908 8.29251 12.0908C11.9658 12.0908 14.9265 15.1743 14.9265 18.9999H16.585C16.585 15.4441 14.4892 12.3877 11.5318 11.0653C13.0769 9.97561 14.0973 8.1269 14.0973 6.04541C14.0973 2.71571 11.4897 0 8.29251 0ZM8.29251 1.72726C10.5924 1.72726 12.4388 3.65019 12.4388 6.04541C12.4388 8.44064 10.5924 10.3636 8.29251 10.3636C5.99264 10.3636 4.14626 8.44064 4.14626 6.04541C4.14626 3.65019 5.99264 1.72726 8.29251 1.72726Z" fill="#0465E2" />
                    </svg>
                </div>
            )
        },

    ];
    const handleClick = (ID) => {
        navigate("/UsersDetails", { state: { id: ID } })
    }
    const handleApproveAll = () => {
        axiosInstance().get(`api/approve-today-reports`)
            .then(res => {
                console.log(res)
                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        axiosInstance().get(`api/get-payment-types`)
            .then(res => {
                setTypes(res.data.data)

            })
            .catch(err => {
                console.log(err)

            })

    }, [])
    const handleSubmit = (e) => {
        e.preventDefault()
        axiosInstance().post(`api/add-cashflow`, { type: incomeType, payment_type: paymentType, amount })
            .then(res => {
                navigate(0)
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleDateSearch = () => {
        setLoading(true)
        axiosInstance().post(`api/get-reports-by-range`, { from: firstDate, to: secondDate })
            .then(res => {
                setLoading(false)
                console.log(res)
                setDateData(res.data.data.total)
                setData(res.data.data.empsTotals)
            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }
    return (
        <>
            <h4>كل التقارير</h4>
            {/* <div className="all-reports-add-new">
                <a className="add-meter-button-users" data-bs-toggle="modal" data-bs-target="#exampleModal" >اضافة</a>
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <h5>اضافة تقرير</h5>
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-3 mb-4">
                                        <div className="col">
                                            <select id="inputState" className="form-select" required defaultValue="القطاع" onChange={(e) => setPaymentType(e.target.value)}>
                                                <option selected>اختر نوع المصروف</option>
                                                {types && types.map(item =>
                                                    (<option value={item}> {item}</option>)
                                                )}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <select id="inputState" className="form-select" required defaultValue="القطاع" onChange={(e) => setIncomeType(e.target.value)} >
                                                <option>اختر الحالة</option>
                                                <option value="income">المدخول</option>
                                                <option value="outcome">المدفوع</option>
                                            </select>
                                        </div>
                                        <div className="row g-3 mb-4">
                                            <div className="col">
                                                <input type="text" className="form-control" placeholder="الكمية" aria-label="is-limited" style={{ width: "50%" }} onChange={(e) => setAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="add-meter-button-users">إضافة</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="widgets-con home-widget-con">

                <div className="widget-1 home-widget-1">
                    <p>الإجمالي الكلي</p>
                    <p>{dataSource && dataSource.total}</p>
                </div>
                <div className="widget-1 home-widget-1">
                    <p>اجمالي اليوم</p>
                    <p>{dataSource && dataSource.daily_total}</p>

                </div>

                <div className="widget-1 home-widget-1">
                    <p>اجمالي الاسبوع</p>
                    <p>{dataSource && dataSource.last_week_total}</p>
                </div>
            </div>
            <div className="container con-date">
                <div className="row">
                    <div className="col-md-6 col-flex-center">
                        <form>
                            <label for="from">من</label>
                            <input type="date" id="from" className="mb-4 mt-4" placeholder="من" onChange={(e) => setFirstDate(e.target.value)} />
                            <label for="to" className="mb-3">الى</label>
                            <input type="date" id="to" placeholder="الى" onChange={(e) => setSecondDate(e.target.value)} />
                            <a className="date-button mt-3" onClick={handleDateSearch}>بحث</a>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <div className="widget-1 home-widget-1">
                            <p>اجمالي الفترة</p>
                            <p>{dateData && dateData.total}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="widgets-con reports-con">
                 {location.state.endPoint == "get-outcome-reports" ? <></> : <>
                    <div className="widget-1" onClick={() => setFilter("1")}>
                        <p>واصل</p>
                    </div>
                    <div className="widget-1" onClick={() => setFilter("0")}>
                        <p>غير واصل</p>
                    </div>
                </>} 

            </div>  */}
            {/* <div className="approve-all-con">
                <a className="add-meter-button-users">تثبيت الكل</a>
            </div>
            <div className="approve-all-con">
                <a className="add-meter-button-users" >الإجمالي:{total}</a>
            </div> */}
            <div className="table-con mt-5">
                <Table dataSource={data && data} columns={columns} pagination={{
                    pageSize: 20,
                    total: totalPages,
                    onChange: (page) => {
                        fetchData(page)
                    }
                }} rowKey={record => record.id} loading={loading} />
            </div>
        </>
    )
}

export default AllReports