import { Table } from "antd"
import "./Orders.css"

const Orders = () => {
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            userName: 32,
            phoneNumber: '10 Downing Street',
            Status: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
        {
            key: '3',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
        {
            key: '4',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },

    ];

    const columns = [

        {
            title: ' رقم الأمبير',
            dataIndex: 'amberNumber',
            key: 'amberNumber',
        },
        {
            title: 'تاريخ الطلب',
            dataIndex: 'orderTime',
            key: 'orderTime',
        },
        {
            title: ' اسم المالك',
            dataIndex: 'ownerName',
            key: 'ownerName',
        },
        {
            title: 'الرقم الوطني',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: ' القيمة المراد شحنها',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'السعر',
            dataIndex: 'price',
            key: 'price',
        },
        {
            dataIndex: 'profile',
            key: 'profile',
            render: (text, record) => (
                <div className="profile-con-table">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M8.29251 0C5.09536 0 2.48775 2.71571 2.48775 6.04541C2.48775 8.1269 3.50812 9.97561 5.05325 11.0653C2.0958 12.3877 0 15.4441 0 18.9999H1.6585C1.6585 15.1743 4.61919 12.0908 8.29251 12.0908C11.9658 12.0908 14.9265 15.1743 14.9265 18.9999H16.585C16.585 15.4441 14.4892 12.3877 11.5318 11.0653C13.0769 9.97561 14.0973 8.1269 14.0973 6.04541C14.0973 2.71571 11.4897 0 8.29251 0ZM8.29251 1.72726C10.5924 1.72726 12.4388 3.65019 12.4388 6.04541C12.4388 8.44064 10.5924 10.3636 8.29251 10.3636C5.99264 10.3636 4.14626 8.44064 4.14626 6.04541C4.14626 3.65019 5.99264 1.72726 8.29251 1.72726Z" fill="#0465E2" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
                        <path d="M6.05714 0C5.66082 0 5.25563 0.145344 4.96875 0.44531C4.68186 0.745275 4.54285 1.16894 4.54285 1.58332V2.37498H0V3.95831H0.757142V16.6249C0.757142 17.9268 1.78343 18.9999 3.02857 18.9999H12.1143C13.3594 18.9999 14.3857 17.9268 14.3857 16.6249V3.95831H15.1428V2.37498H10.6V1.58332C10.6 1.16894 10.461 0.745275 10.1741 0.44531C9.88722 0.145344 9.48203 0 9.08571 0H6.05714ZM6.05714 1.58332H9.08571V2.37498H6.05714V1.58332ZM2.27143 3.95831H12.8714V16.6249C12.8714 17.064 12.5343 17.4166 12.1143 17.4166H3.02857C2.60859 17.4166 2.27143 17.064 2.27143 16.6249V3.95831ZM3.78571 6.33329V15.0416H5.3V6.33329H3.78571ZM6.81428 6.33329V15.0416H8.32857V6.33329H6.81428ZM9.84285 6.33329V15.0416H11.3571V6.33329H9.84285Z" fill="#E28104" />
                    </svg>
                </div>
            )
        },

    ];
    return (
        <>
            <h4>الطلبات</h4>
            <div className="widgets-con">
                <div className="widget-1">
                    <p>الكل</p>
                </div>
                <div className="widget-1">
                    <p>انتظار التجديد</p>
                    <p>124</p>
                </div>
                <div className="widget-1">
                    <p> تم التجديد</p>
                    <p>290</p>
                </div>
            </div>
            <div className="table-con">
                <Table dataSource={dataSource} columns={columns} pagination={{
                    pageSize: 5
                }} />
            </div>
        </>
    )
}

export default Orders