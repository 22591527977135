import "./AddMeter.css"

const AddMeter = () => {
    return (
        <>
            <h4>إضافة عداد</h4>
            <form className="row g-3 form-add-user">
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="firstName" className="form-label">رقم العداد</label>
                        <input type="text" className="form-control" id="firstName" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="lastName" className="form-label">اسم المستخدم</label>
                        <input type="text" className="form-control" id="lastName" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="fatherName" className="form-label">اسم الأب :</label>
                        <input type="text" className="form-control" id="fatherName" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="phoneNumber" className="form-label">رقم الهاتف :</label>
                        <input type="text" className="form-control" id="phoneNumber" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="number" className="form-label"> الرقم الارضي :</label>
                        <input type="text" className="form-control" id="number" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-input">
                        <label for="nationalId" className="form-label">الرقم الوطني :</label>
                        <input type="text" className="form-control" id="nationalId" />
                    </div>
                </div>
                <div className="col-md-12 specificLocation-col ">
                    <div className="form-input">
                        <label for="specificLocation" className="form-label">العنوان بالتفصيل:</label>
                        <input type="text" className="form-control" id="specificLocation" />
                    </div>
                </div>
                <div className="col-md-12 add-con">
                    <a className="add-button">إضافة</a>
                </div>
            </form>
        </>
    )
}

export default AddMeter