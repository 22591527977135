import "./DailyReports.css"
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../axios-instance";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";

const DailyReports = () => {
    const [filter, setFilter] = useState()
    const [dataSource, setDataSource] = useState()
    const [loading, setLoading] = useState(true)
    const [totalPages, setTotalPages] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        fetchData(1)
    }, [filter])
    const fetchData = (page) => {
        setLoading(true)
        axiosInstance().get(`api/get-today-reports/${filter}page=${page}`)
            .then(res => {
                setLoading(false)
                setDataSource(res.data.data.data.map(item => {
                    return { ...item, employee_name: item.user.user_name }
                }))
            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }
    const handleApprove = (ID) => {
        axiosInstance().get(`api/approve-report/${ID}`)
            .then(res => {

                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    const handleDisApprove = (ID) => {
        axiosInstance().get(`api/disapprove-report/${ID}`)
            .then(res => {

                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    const columns = [

        {
            title: 'اسم الموظف',
            dataIndex: 'employee_name',
            key: 'employee_name',
        },
        {
            title: 'الحالة',
            dataIndex: 'is_approved',
            key: 'is_approved',
            render: (text, record) => (
                <p className="m-0 p-0">{record.is_approved == 0 ? "غير واصل" : record.role_id == 1 && "واصل"}</p>
            )
        },
        {
            title: 'المبلغ',
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: 'التاريخ',
            dataIndex: 'created_at',
            key: 'created_at'
        },
        {
            title: 'ملاحظات',
            dataIndex: 'notes',
            key: 'notes'
        },

        {
            dataIndex: 'profile',
            key: 'profile',
            render: (text, record) => (
                <div className="profile-con-table" key={record.id}>
                    <BsCheckLg size={20} className="check" onClick={() => handleApprove(record.id)} />
                    <FaTimes size={20} className="times" onClick={() => handleDisApprove(record.id)} />
                </div>
            )
        },

    ];
    const handleApproveAll = () => {
        axiosInstance().get(`api/approve-today-reports`)
            .then(res => {
                console.log(res)
                navigate("/Dashboard")
            })
            .catch(err => {
                console.log(err)
            })
    }
    return (
        <>
            <h4>التقارير اليومية</h4>
            <div className="widgets-con reports-con">
                <div className="widget-1" onClick={() => setFilter("1")}>
                    <p>واصل</p>
                </div>
                <div className="widget-1" onClick={() => setFilter("0")}>
                    <p>غير واصل</p>
                </div>
            </div>
            <div className="approve-all-con">
                <a className="add-meter-button-users" onClick={handleApproveAll}>تثبيت الكل</a>
            </div>
            <div className="table-con">
                <Table dataSource={dataSource && [dataSource]} columns={columns} pagination={{
                    pageSize: 20,
                    total: totalPages,
                    onChange: (page) => {
                        fetchData(page)
                    }
                }} rowKey={record => record.id} loading={loading} />
            </div>
        </>
    )
}

export default DailyReports