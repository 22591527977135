import "./Dashboard.css"
import { Sidebar } from "../../Component";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Orders from "../Orders/Orders";
import Users from "../Users/Users";
import InfinitySubscribtion from "../InfinitySubscribtion/InfinitySubscribtion";
import LimitSubscribtion from "../LimitSubscribtion/LimitSubscribtion";
import AddUser from "../AddUser/AddUser";
import AddMeter from "../AddMeter/AddMeter";
import AllReports from "../AllReports/AllReports";
import DailyReports from "../DailyReports/DailyReports";
import Settings from "../Settings/Settings";

const Dashboard = () => {
    return (
        <>
            <div className="container-fluid dashboard-con">
                <div className="row">
                    <div className="col-md-10">
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='Orders' element={<Orders />} />
                            {/* <Route path='AmberCounter' element={<AmberCounter />} /> */}
                            <Route path='Users' element={<Users />} />
                            <Route path='InfinitySubscribtion' element={<InfinitySubscribtion />} />
                            <Route path='LimitSubscribtion' element={<LimitSubscribtion />} />
                            <Route path='AddUser' element={<AddUser />} />
                            <Route path='AddMeter' element={<AddMeter />} />
                            <Route path='AllReports' element={<AllReports />} />
                            <Route path='DailyReports' element={<DailyReports />} />
                            <Route path='Settings' element={<Settings />} />
                        </Routes>

                    </div>
                    <Sidebar />
                </div>
            </div>
        </>
    )
}

export default Dashboard