import './App.css';
import { Route, Routes } from 'react-router-dom';
import { BillDetails, Dashboard, MeterDetails, SignIn, UsersDetails } from './Pages';
import PrivateRoutes from './Utlis/PrivateRoutes';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route element={<PrivateRoutes />}>
          <Route path='/Dashboard//*' element={<Dashboard />} />
          <Route path='/BillDetails' element={<BillDetails />} />
          <Route path='/MeterDetails' element={<MeterDetails />} />
          <Route path='/UsersDetails' element={<UsersDetails />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
