import { useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookies"
const PrivateRoutes = () => {
    const [token, setToken] = useState(Cookies.getItem("token"))
    return (
        <>
            {token ? <Outlet /> : <Navigate to="/" />}
        </>
    )
}
export default PrivateRoutes