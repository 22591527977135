import { Table } from "antd"
import "./InfinitySubscribtion.css"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { axiosInstance } from "../../axios-instance"
import { GrFormAdd } from "react-icons/gr";
import { AiFillInfoCircle } from "react-icons/ai";


const InfinitySubscribtion = () => {
    const [dataSource, setDataSource] = useState([])
    const [filter, setFilter] = useState()
    const [consumption, setConsumption] = useState("0")
    const [status, setStatus] = useState()
    const [search, setSearch] = useState()
    const [price, setPrice] = useState()
    const [validation, setValidation] = useState()
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState()
    const [totalPages, setTotalPages] = useState()
    const [sectionSearch, setSectionSearch] = useState()
    const [isSearch, setIsSearch] = useState(false)
    const [isSectionSeacrh, setIsSectionSearch] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        fetchData(1)
    }, [filter]
    )
    const handleInfo = (ID) => {
        navigate("/MeterDetails", { state: { id: ID } })
    }
    const handleAddBill = (e, ID) => {
        e.preventDefault()
        axiosInstance().post(`api/add-weekly-consumption`, { ampere_meter_id: ID, consumption, amount: price, is_payed: status })
            .then(res => {
                window.location.assign("/Dashboard")
                setValidation(true)
            })
            .catch(err => {
                console.log(err)
                setValidation(false)
                setErrorMessage(err.response.data.message)
            })
    }
    const handleClick = (ID) => {
        navigate("/UsersDetails", { state: { id: ID } })
    }
    const fetchData = (page) => {
        setLoading(true)
        axiosInstance().get(`api/get-unlimited-bills/${filter}?page=${page}`)
            .then(res => {
                setTotalPages(res.data.data.total)
                setLoading(false)
                setDataSource(res.data.data.data.map(item => {
                    return { ...item, user_name: item.user.user_name, user_id: item.user.id, name: item.user.user_details.sub_location.name }
                }))

            })
            .catch(err => {
                setLoading(true)
                console.log(err)
            })
    }
    const handleSectionSearch = (page) => {
        setLoading(true)
        axiosInstance().get(`api/unlimited-bills-by-location${sectionSearch == null || sectionSearch == "" ? `/${null}` : `/${sectionSearch}`}${sectionSearch != "" ? `?page=${page}` : ""}`)
            .then(res => {
                console.log(res)
                setTotalPages(res.data.data.total)
                setIsSectionSearch(true)
                setDataSource(res.data.data.data.map(item => {
                    return { ...item, user_name: item.user.user_name, user_id: item.user.id, name: item.user.user_details.sub_location.name }
                }))
                setLoading(false)
            })
            .catch(err => {
                setIsSectionSearch(false)
                console.log(err)
                setLoading(true)
            })
    }
    const columns = [

        {
            title: 'رقم الأمبير',
            dataIndex: 'meter_number',
            key: 'meter_number',
        },
        {
            title: 'نوع الاشتراك',
            dataIndex: 'is_limited',
            key: 'is_limited',
        },
        {
            title: ' اسم المالك',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'القطاع',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'رقم التابلو',
            dataIndex: 'tableau_number',
            key: 'tableau_number',
        },

        {
            title: ' الاستهلاك الكلي',
            dataIndex: 'total_consumption',
            key: 'total_consumption'
        },

        {
            dataIndex: 'profile',
            key: 'profile',
            render: (text, record) => (
                <div className="profile-con-table" key={record.id}>
                    <svg onClick={() => handleClick(record.user_id)} xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                        <path d="M8.29251 0C5.09536 0 2.48775 2.71571 2.48775 6.04541C2.48775 8.1269 3.50812 9.97561 5.05325 11.0653C2.0958 12.3877 0 15.4441 0 18.9999H1.6585C1.6585 15.1743 4.61919 12.0908 8.29251 12.0908C11.9658 12.0908 14.9265 15.1743 14.9265 18.9999H16.585C16.585 15.4441 14.4892 12.3877 11.5318 11.0653C13.0769 9.97561 14.0973 8.1269 14.0973 6.04541C14.0973 2.71571 11.4897 0 8.29251 0ZM8.29251 1.72726C10.5924 1.72726 12.4388 3.65019 12.4388 6.04541C12.4388 8.44064 10.5924 10.3636 8.29251 10.3636C5.99264 10.3636 4.14626 8.44064 4.14626 6.04541C4.14626 3.65019 5.99264 1.72726 8.29251 1.72726Z" fill="#0465E2" />
                    </svg>
                    <AiFillInfoCircle className="info-icon" size="20" onClick={() => handleInfo(record.id)} />
                    <a className="add-meter-button" data-bs-toggle="modal" data-bs-target="#addBill">
                        <GrFormAdd size={20} />
                    </a>
                    <div className="modal fade" id="addBill" tabIndex="-1" aria-labelledby="addBillLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h5>اضافة فاتورة</h5>
                                    <form onSubmit={(e) => handleAddBill(e, record.id)}>
                                        <div className="row g-3 mb-4 ">
                                            <div className="col-6">
                                                <select id="inputState" className="form-select" required onChange={(e) => setStatus(e.target.value)} >
                                                    <option>الحالة</option>
                                                    <option value="1">مدفوعة</option>
                                                    <option value="0">غير مدفوعة</option>
                                                </select>
                                            </div>
                                            <div className="col-6 flex-css">
                                                <input type="text" className="form-control" placeholder="التأشيرة الحالية" aria-label="consumption" onChange={(e) => setConsumption(e.target.value)} />
                                            </div>
                                            {status == "1" ?
                                                <div className="col-4">
                                                    <input type="text" className="form-control" placeholder="المبلغ" defaultValue={price} aria-label="amount" onChange={(e) => setPrice(e.target.value)} required />
                                                </div> : <></>}
                                        </div>
                                        {validation == true && <div className="alert alert-success sign-in-danger" role="alert">
                                            اضافة الفاتورة تم بنجاح
                                        </div>}
                                        {validation == false && <div className="alert alert-danger sign-in-danger" role="alert">
                                            {errorMessage}
                                        </div>}
                                        <button className="add-meter-button-users" >إضافة</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },

    ];
    const handleSearch = (page) => {
        axiosInstance().get(`api/search-meter${search == null || search == "" ? `/${null}` : `/${search}`}${search != "" ? `?page=${page}` : ""}`)
            .then(res => {
                setIsSearch(true)
                setDataSource(res.data.data)
            })
            .catch(err => {
                setIsSearch(false)
                console.log(err)
            })
    }
    return (
        <>
            <h4> الاشتراكات الغير محدودة</h4>
            <div className="input-group search-group">
                <a className="search-icon" onClick={() => handleSearch(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M15.0956 0.543457C9.97087 0.543457 5.79771 4.71662 5.79771 9.84136C5.79771 12.0678 6.57859 14.109 7.88974 15.7107L0.480469 23.1199L1.81704 24.4565L9.22631 17.0472C10.828 18.3584 12.8692 19.1393 15.0956 19.1393C20.2204 19.1393 24.3935 14.9661 24.3935 9.84136C24.3935 4.71662 20.2204 0.543457 15.0956 0.543457ZM15.0956 2.40304C19.2143 2.40304 22.5339 5.72268 22.5339 9.84136C22.5339 13.96 19.2143 17.2797 15.0956 17.2797C10.9769 17.2797 7.65729 13.96 7.65729 9.84136C7.65729 5.72268 10.9769 2.40304 15.0956 2.40304Z" fill="#FAFCFF" />
                    </svg>
                </a>
                <input className="search-con" placeholder="بحث ..." onChange={(e) => setSearch(e.target.value)} />

            </div>
            <div className="input-group search-group">
                <a className="search-icon" onClick={() => handleSectionSearch(1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path d="M15.0956 0.543457C9.97087 0.543457 5.79771 4.71662 5.79771 9.84136C5.79771 12.0678 6.57859 14.109 7.88974 15.7107L0.480469 23.1199L1.81704 24.4565L9.22631 17.0472C10.828 18.3584 12.8692 19.1393 15.0956 19.1393C20.2204 19.1393 24.3935 14.9661 24.3935 9.84136C24.3935 4.71662 20.2204 0.543457 15.0956 0.543457ZM15.0956 2.40304C19.2143 2.40304 22.5339 5.72268 22.5339 9.84136C22.5339 13.96 19.2143 17.2797 15.0956 17.2797C10.9769 17.2797 7.65729 13.96 7.65729 9.84136C7.65729 5.72268 10.9769 2.40304 15.0956 2.40304Z" fill="#FAFCFF" />
                    </svg>
                </a>
                <input className="search-con" placeholder="عرض حسب الحي" onChange={(e) => setSectionSearch(e.target.value)} />

            </div>
            <div className="widgets-con">
                <div className="widget-1" onClick={() => setFilter("")}>
                    <p>الكل</p>
                </div>
                <div className="widget-1" onClick={() => setFilter("1")}>
                    <p>مدفوعة</p>
                </div>
                <div className="widget-1" onClick={() => setFilter("0")}>
                    <p> غير مدفوعة</p>
                </div>
            </div>
            <div className="table-con">
                <Table dataSource={dataSource && dataSource} columns={columns} pagination={{
                    pageSize: 20,
                    total: totalPages,
                    onChange: (page) => {
                        // { isSearch == true ? handleSearch(page) : fetchData(page) }
                        { isSectionSeacrh == true ? handleSectionSearch(page) : fetchData(page) }
                    }
                }} rowKey={record => record.id} loading={loading} />
            </div>
        </>
    )
}

export default InfinitySubscribtion